<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-tabs pills>
      <b-tab title="Ürünlerim" @click="geri()" :active="activeTab == 'tab1'">
        <b-row>
          <b-col xl="2" xs="0">
            <b-card>
              <b-row>
                <b-col cols="12">
                  <b-button @click="brandShow()" variant="warning" block>
                    <feather-icon icon="PlusIcon" /> Marka Ekle
                  </b-button></b-col
                >
              </b-row>
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-button @click="categoryShow()" variant="warning" block>
                    <feather-icon icon="PlusIcon" /> Kategori Ekle
                  </b-button></b-col
                >
              </b-row>
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-button variant="success" block @click="add()">
                    Ürün Ekle
                  </b-button></b-col
                >
              </b-row>
            </b-card>
          </b-col>
          <b-col xl="10" xs="12">
            <b-row style="overflow-y: scroll; height: 78vh">
              <b-col
                xl="3"
                md="4"
                sm="6"
                xs="12"
                v-for="product in products"
                :key="product.id"
              >
                <b-card
                  no-body
                  style="
                    padding: 5px;
                    height: 350px !important;
                    max-height: 350px !important;
                  "
                >
                  <div class="image-container">
                    <b-img
                      fluid
                      class="card-img-top shrink-on-hover"
                      :src="product.urlPath"
                      style="border-radius: 10px"
                    />
                  </div>
                  <!-- Product Details -->
                  <b-card-body>
                    <h6 class="item-name">
                      {{ product.name }}
                    </h6>
                    <span class="item-name">
                      {{ product.sellPrice }} {{ product.priceUnit }}
                    </span>
                  </b-card-body>

                  <div class="item-options text-center">
                    <b-row>
                      <b-col cols="6">
                        <b-button
                          variant="success"
                          block
                          @click="detay(product)"
                        >
                          Düzenle
                        </b-button></b-col
                      >
                      <b-col cols="6">
                        <b-button
                          variant="danger"
                          block
                          @click="deleteProduct(product)"
                        >
                          Sil
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Detay" @click="detay('bos')" :active="activeTab == 'tab2'">
        <b-card v-if="selectedProduct != ''">
          <b-row>
            <b-col cols="6">
              <b-row
                ><b-col>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Ürün Adı:</h5>
                    </b-col>
                    <b-col cols="8">
                      <b-form-input
                        size="sm"
                        block
                        v-model="selectedProduct.name"
                    /></b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Ürün Kodu:</h5>
                    </b-col>
                    <b-col cols="8">
                      <b-form-input
                        size="sm"
                        block
                        v-model="selectedProduct.barcode"
                    /></b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Ürün Açıklama:</h5>
                    </b-col>
                    <b-col cols="8">
                      <b-form-textarea
                        id="name-input"
                        v-model="selectedProduct.description"
                        size="sm"
                    /></b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Ürün Özellikleri:</h5>
                    </b-col>
                    <b-col cols="8">
                      <b-form-textarea
                        id="name-input"
                        v-model="selectedProduct.feature"
                        size="sm"
                    /></b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Indexler:</h5>
                    </b-col>
                    <b-col cols="8">
                      <b-form-textarea
                        id="name-input"
                        v-model="selectedProduct.index"
                        size="sm"
                    /></b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Ürün Fiyatı:</h5>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        size="sm"
                        type="number"
                        v-model="selectedProduct.sellPrice"
                    /></b-col>
                    <b-col cols="2"
                      ><h5 style="margin-top: 5px">Para Birimi:</h5>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        size="sm"
                        v-model="selectedProduct.priceUnit"
                    /></b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Kategori:</h5>
                    </b-col>
                    <b-col cols="8">
                      <b-form-select
                        v-model="selectedProduct.categoryId"
                        :options="categories"
                        size="sm"
                    /></b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="3"
                      ><h5 style="margin-top: 5px">Marka:</h5>
                    </b-col>
                    <b-col cols="8">
                      <b-form-select
                        v-model="selectedProduct.brandId"
                        :options="brands"
                        size="sm"
                    /></b-col>
                  </b-row> </b-col
              ></b-row>
              <b-row class="mb-1">
                <b-col cols="7"></b-col>
                <b-col cols="4">
                  <b-button variant="success" block @click="updateProduct()"
                    >Kaydet</b-button
                  >
                </b-col>
              </b-row>
              <hr />
              <b-row
                ><b-col cols="11">
                  <div class="border rounded p-2">
                    <b-media no-body vertical-align="center">
                      <b-media-body>
                        <small
                          >Gerekli Çözünürlük 1024x1024, Resim Boyutu Max 5mb
                          olmalıdır.</small
                        >
                        <div>
                          <b-form-file
                            ref="refInputEl"
                            v-model="featuredImage"
                            accept=".jpg, .png, .jpeg"
                            placeholder="Resim Seç"
                            @input="inputImageRenderer"
                          />
                        </div>
                      </b-media-body>
                    </b-media></div></b-col
              ></b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12" class="text-center">
                  <b-card
                    style="
                      background-color: #f1f1f1;
                      height: 400px !important;
                      max-height: 400px !important;
                    "
                  >
                    <h5>Kapak Resmi</h5>
                    <img
                      class="center"
                      :src="selectedProduct.urlPath"
                      style="
                        max-height: 350px !important;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                      "
                    /> </b-card
                ></b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-card style="background-color: #f1f1f1">
                    <swiper
                      class="swiper-responsive-breakpoints px-4 py-0"
                      :options="swiperOptions"
                    >
                      <swiper-slide
                        v-for="(imag, index) in productImages"
                        :key="index"
                      >
                        <b-link>
                          <div
                            class="img-container w-55 mx-auto py-75 text-right"
                          >
                            <b-img
                              :src="imag.urlPath"
                              fluid
                              @click="selectedCover(imag)"
                            />
                            <b-button
                              @click="pictureRemove(imag)"
                              size="sm"
                              variant="danger"
                              pill
                            >
                              <feather-icon icon="TrashIcon" /> Sil
                            </b-button>
                          </div>
                        </b-link>
                      </swiper-slide>

                      <!-- Add Arrows -->
                      <div slot="button-next" class="swiper-button-next" />
                      <div slot="button-prev" class="swiper-button-prev" />
                    </swiper> </b-card
                ></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-product"
      ref="my-modal"
      title="Ürün Bilgileri"
      hide-footer
      size="lg"
      size-sm
    >
      <form ref="form">
        <b-form-group label="Marka Bilgisi" label-for="name-select">
          <b-form-select
            v-model="product.brandId"
            :options="brands"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Kategori Bilgisi" label-for="name-select">
          <b-form-select
            v-model="product.categoryId"
            :options="categories"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Ürün Adı" label-for="name-input">
          <b-form-input id="name-input" v-model="product.name" size="sm" />
        </b-form-group>
        <b-form-group label="Ürün Açıklaması" label-for="name-input">
          <b-form-input
            id="name-input"
            v-model="product.description"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Ürün Özellikleri" label-for="name-input">
          <b-form-textarea
            id="name-input"
            v-model="product.feature"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Website Ürün İndexleri" label-for="name-input">
          <b-form-textarea id="name-input" v-model="product.index" size="sm" />
        </b-form-group>
        <b-form-group label="Ürün Kodu">
          <b-form-input size="sm" id="name-input" v-model="product.barcode" />
        </b-form-group>

        <b-form-group label="Satış Fiyatı" label-for="name-input">
          <b-form-input
            size="sm"
            id="name-input"
            type="number"
            v-model="product.sellPrice"
          />
        </b-form-group>
        <b-form-group label="Para Birimi" label-for="name-select">
          <b-form-select
            v-model="product.priceUnit"
            :options="priceUnits"
            size="sm"
          />
        </b-form-group>

        <b-button class="mt-2" block variant="danger" @click="addProduct()"
          >Ekle</b-button
        >
        <b-button class="mt-2" block @click="hideModal">iptal</b-button>
      </form>
    </b-modal>
    <b-modal
      id="my-brand"
      title="Marka Ekle"
      ok-title="Ekle"
      cancel-title="İptal"
      cancel-variant="outline-secondary"
      @ok="addBrand"
      size="lg"
      size-sm
    >
      <form ref="form" @submit.stop.prevent="add">
        <b-form-group label="Marka Adı" label-for="name-input">
          <b-form-input size="sm" id="name-input" v-model="brandName" />
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="my-category"
      title="Kategori Ekle"
      ok-title="Ekle"
      cancel-title="İptal"
      cancel-variant="outline-secondary"
      @ok="addCategory"
      size="lg"
      size-sm
    >
      <form ref="form" @submit.stop.prevent="add">
        <b-form-group label="Kategori Adı" label-for="name-input">
          <b-form-input size="sm" id="name-input" v-model="categoryName" />
        </b-form-group>
      </form>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
  BFormInput,
  BOverlay,
  BModal,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormTextarea,
  BTab,
  BTabs,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BDropdown,
    BImg,
    BFormSpinbutton,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BModal,
    BCardHeader,
    BCardBody,
    BRow,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BCol,
    BTable,
    BFormFile,
    BMedia,
    BAvatar,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    BTab,
    BTabs,
    Swiper,
    SwiperSlide,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      show: false,
      userData: "",
      products: [],
      brands: [],
      brandName: "",
      priceUnits: [
        { value: "$", text: "USD" },
        { value: "€", text: "EUR" },
        { value: "₺", text: "TRY" },
      ],
      categories: [],
      categoryName: "",
      product: {
        customerId: 0,
        name: "",
        description: "",
        feature: "",
        barcode: "",
        brandId: null,
        sellPrice: 0,
        categoryId: null,
        priceUnit: null,
        index: "",
      },
      selectedProduct: "",
      activeTab: "tab1",
      productImages: [],
      boxOne: "",
      featuredImage: null,
    };
  },
  computed: {},
  created() {
    var uData = JSON.parse(localStorage.getItem("HPUserData"));
    this.userData = uData;
    this.product.customerId = this.userData.customerId;
    this.getProduct();
    this.getBrand();
    this.getCategory();
  },

  setup() {
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    };

    return {
      swiperOptions,
    };
  },
  methods: {
    // Detay
    async updateProduct() {
      if (this.selectedProduct.name == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Ürün Adı Boş Olamaz..",
          },
        });
        return;
      }
      this.show = true;
      var updateModel = {
        id: this.selectedProduct.id,
        name: this.selectedProduct.name,
        description: this.selectedProduct.description,
        feature: this.selectedProduct.feature,
        barcode: this.selectedProduct.barcode,
        categoryId: this.selectedProduct.categoryId,
        brandId: this.selectedProduct.brandId,
        sellPrice: this.selectedProduct.sellPrice,
        priceUnit: this.selectedProduct.priceUnit,
        index: this.selectedProduct.index,
      };
      this.$http
        .put("webimage/update", updateModel)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            await this.getProduct();

            this.activeTab = "tab1";
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async detay(data) {
      if (data == "bos") {
        this.selectedProduct = "";
        this.featuredImage = null;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Görüntülenecek ürün seçiniz..",
          },
        });
        return;
      }
      this.selectedProduct = data;
      this.featuredImage = null;
      await this.getPicture(data.id);
    },
    async getPicture(productId) {
      this.show = true;
      this.$http
        .get("document?productId=" + productId)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.productImages = [];
            this.productImages = satirlar;
            for (var i = 0; i < satirlar.length; i++) {
              if (satirlar[i].cover == true) {
                this.selectedProduct.urlPath = satirlar[i].urlPath;
              }
            }
            this.activeTab = "tab2";
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async selectedCover(imag) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Kapak Resmi Yapmak İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "primary",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxOne = value;
          if (value == true) {
            this.show = true;
            this.$http
              .put("document?productId=" + imag.id)
              .then((response) => {
                if (response.status === 200) {
                  this.selectedProduct.urlPath = imag.urlPath;
                  for (var i = 0; i < this.productImages.length; i++) {
                    this.productImages[i].cover = false;
                  }
                  imag.cover = true;
                  this.show = false;
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: "Kapak Resmi Değiştirildi..",
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async geri() {
      this.activeTab = "tab1";
      this.selectedProduct = "";
      this.productImages = [];
    },
    async pictureRemove(imag) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Resmi Silmek İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "primary",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxOne = value;
          if (imag.cover == true) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hata`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: "Kapak Resmi Silinemez...",
              },
            });
            return;
          }

          if (value == true) {
            this.show = true;
            this.$http
              .delete(`document?id=${imag.id}`)
              .then((response) => {
                if (response.status === 200) {
                  this.productImages = this.productImages.filter(
                    (i) => i.id !== imag.id
                  );
                  this.show = false;

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Silme İşlemi Başarılı..`,
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async inputImageRenderer(data) {
      if (data.size > 5242880) {
        this.featuredImage = null;
        this.$refs.refInputEl.reset();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Yüklemek istediğiniz dosya boyutu çok yüksek..!",
          },
        });
        return;
      }

      this.show = true;
      this.featuredImage = data;
      let formData = new FormData();
      formData.append("file", this.featuredImage);

      var kapak = true;
      if (this.productImages != null) {
        if (this.productImages.length > 0) {
          kapak = false;
        }
      }
      this.$http
        .post(
          "document?productId=" +
            this.selectedProduct.id +
            "&cover=" +
            kapak +
            "&type=" +
            1 +
            "&description=" +
            "urun", // slider 0 product 1 brand 2
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Dosya yükleme için content type'ı belirtilmelidir
            },
          }
        )
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.featuredImage = null;
            this.$refs.refInputEl.reset();
            this.getPicture(this.selectedProduct.id);
          }
        })
        .catch((error) => {
          this.show = false;
          this.featuredImage = null;
          this.$refs.refInputEl.reset();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    ///////////////////////////////////////////////////////////
    // Ön Sayfa
    async add() {
      this.product.name = "";
      this.product.description = "";
      this.product.feature = "";
      this.product.barcode = "";
      this.product.brandId = null;
      this.product.sellPrice = 0;
      this.product.priceUnit = null;
      this.product.categoryId = null;
      this.$bvModal.show("modal-product");
    },
    async deleteProduct(data) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Ürünü Silmek İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "primary",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxOne = value;
          if (value == true) {
            this.show = true;
            this.$http
              .delete(`Webimage?id=${data.id}`)
              .then((response) => {
                if (response.status === 200) {
                  this.products = this.products.filter((i) => i.id !== data.id);
                  this.show = false;
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Silme İşlemi Başarılı..`,
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async addProduct() {
      if (this.product.categoryId == null || this.product.categoryId == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Kategori Boş Olamaz..",
          },
        });
        return;
      }
      if (this.product.name == null || this.product.name == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "İsim Boş Olamaz..",
          },
        });
        return;
      }
      if (this.product.description == null || this.product.description == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Açıklama Boş Olamaz..",
          },
        });
        return;
      }
      if (this.product.feature == null || this.product.feature == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Özellik Boş Olamaz..",
          },
        });
        return;
      }
      if (this.product.barcode == null || this.product.barcode == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Ürün Kodu Boş Olamaz..",
          },
        });
        return;
      }

      this.show = true;
      this.$http
        .post("Webimage", this.product)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            await this.getProduct();
            this.show = false;
            this.$bvModal.hide("modal-product");
          }
        })
        .catch((error) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getProduct() {
      this.show = true;

      this.$http
        .get("Webimage?customerId=" + this.userData.customerId)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.products = satirlar;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async brandShow() {
      this.brandName = "";
      this.$bvModal.show("my-brand");
    },
    async addBrand() {
      if (this.brandName == null || this.brandName == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Marka Boş Olamaz..",
          },
        });
        return;
      }
      var sendModel = { name: this.brandName.toUpperCase() };

      this.$http
        .post("brands", sendModel)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.getBrand();
            this.brandName = "";
            this.$bvModal.hide("modal-prevent-brand");
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getBrand() {
      this.show = true;
      this.$http
        .get("brands")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.brands = [];
            for (var i = 0; i < satirlar.length; i++) {
              var model = {
                value: satirlar[i].id,
                text: satirlar[i].name,
              };
              this.brands.push(model);
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async categoryShow() {
      this.categoryName = "";
      this.$bvModal.show("my-category");
    },
    async addCategory() {
      if (this.categoryName == null || this.categoryName == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Kategori Boş Olamaz..",
          },
        });
        return;
      }
      var sendModel = {
        name: this.categoryName.toUpperCase(),
        customerId: this.userData.customerId,
      };

      this.$http
        .post("categories", sendModel)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.getCategory();
            this.brandName = "";
            this.$bvModal.hide("my-category");
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getCategory() {
      this.show = true;
      this.$http
        .get("categories")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.categories = [];
            for (var i = 0; i < satirlar.length; i++) {
              var model = {
                value: satirlar[i].id,
                text: satirlar[i].name,
              };
              this.categories.push(model);
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
</style>
<style>
.hover-effect {
  transition: font-size 0.3s, text-shadow 0.3s; /* Geçiş efekti eklemek için */
}

.hover-effect:hover {
  font-size: 18px; /* Font boyutunu büyüt (örneğin) */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Metne gölge ekle (örneğin) */
}
.image-container {
  height: 200px; /* Resmin sabit yüksekliği */
  overflow: hidden; /* İçeriğin taşmasını engeller */
  transform-origin: top;
}

/* Küçülme animasyonunu tanımlayan CSS */
.shrink-on-hover {
  transition: transform 0.5s;
}

.shrink-on-hover:hover {
  transform: scale(0.5);
  transform-origin: top;
}
</style>
